.wrapper {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  flex: 1;
}

.coo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin-top: 2.3rem;
  /* margin: 2.3rem 0 2.5rem 0; */
  flex: 3;
}

.coo-content {
  line-height: 1.6;
  margin: 0 auto;
  width: 70%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-justify: auto;
}

.coo-desc {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 20px;
}

.coo-title {
  font-size: 46px;
  font-weight: bold;
  margin-bottom: 3rem;
  line-height: 1.25;
}

.coo-contents-container {
  padding: 2rem 2rem;
}

.coo-callout-title {
  font-size: 28px;
  font-weight: 700;
  text-align: left;
  /* margin-left: 2.3vw; */
  /* color: #000; */
}

.coo-callout-title {
  margin-bottom: 0.5vw;
}

.coo-callout-description,
.coo-callout-description a {
  text-align: left;
  padding: 1rem 1.5rem;
  line-height: 1.6;
  font-size: 1rem;
  font-weight: 400;
}

.coo-callout-description a {
  text-decoration: underline;
  padding: 0 !important;
  color: rgb(5, 5, 99) !important;
}

.coo-callout-description a>span {
  text-decoration: underline;
  padding: 0 !important;
  color: #306188 !important;
}

.coo-callout-description p {
  margin: 0.875rem 0;
}

.coo-callout-description p:first-of-type {
  margin-top: 0;
}

.coo-callout-description p:last-of-type {
  margin-bottom: 0;
}

.coo-callout-description {
  background-color: #f5f5dc;
}

.coo-callout-container {
  margin-bottom: 1.9vw;
}

/* .coo-callout-container:first-child  .coo-callout-title{
  } */

.coo-contents-container {
  width: 100%;
  background-color: rgba(232, 200, 106, 0.5);
  padding: 1rem 2rem 0;
  margin-bottom: 2rem;
}

/* body {
    background-color: antiquewhite;
  } */
@media (max-width: 768px) {
  .coo-content {
    width: 90%;
    margin: 30px auto;
  }

  .coo-callout-title {
    font-size: 1.4rem;
    margin-left: 15px;
  }

  .coo-callout-description,
  .coo-callout-description a {
    padding: 10px;
    font-size: 1rem;
    line-height: 1.6;
  }

  .coo-title {
    font-size: 36px;
    margin-bottom: 3rem;
  }

  .coo-contents-container {
    padding: 20px;
    margin-bottom: 20px;
  }
}
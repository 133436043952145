.fgact-wrapper {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  background-color: #fffaeb;
  flex: 1;
}

.ACT-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  flex: 3;
  /* margin-top: 30px; */
}

.ACT-content {
  margin: 17px auto;
  margin-top: 2.5rem;
  max-width: 60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-justify: auto;
}

.image-overlay {
  /* z-index: -1; */
  /* color: rgba(0, 0, 0); */
  height: 100%;
  width: 100%;
  color: white;
  position: "absolute";
  z-index: 1;
}

.ACT-title {
  font-size: 46px;
  font-weight: bold;
  margin-bottom: 40px;
}

.ACT-paragraph {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
  font-family: "Open Sans", "Noto Serif TC", sans-serif !important;
}



img.ACT-image {
  max-width: 100% !important;
  max-height: 100%;
  width: 100% !important;
  object-fit: cover;
  object-position: top;
  position: relative;
  z-index: 0;

  height: 100%;

}



/* .ACT-image{
  width: 100%!important;
  object-fit: cover;
  position: relative;
  z-index: 0;
} */

.roadmap-container {
  width: 100%;
  position: relative;
  /* margin-bottom: -10px; */
}

.button-container-outer {
  position: relative;
}

.button-row {
  display: flex;
  position: absolute;
  width: 100%;
  top: 0;
  justify-content: center;
  height: 14vw;
}

.button-container {
  width: 14vw;
  height: 14vw;
  display: flex;
  position: relative;
  left: 6%;
}

.button-container.active {
  width: 15vw;
  height: 15vw;
  top: -0.5vw;
}

a.active+p.left {
  left: -23.5vw;
}

a.active+p.right {
  left: 17.5vw;
}

.button-bg {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
}

.button-hidden-bg {
  /* z-index: -1; */
  /* position: absolute; */
  width: 100%;
  height: 100%;
  filter: grayscale(45%);
  /* filter: brightness(1.5); */
}

.lock {
  width: 50%;
  height: 50%;
  position: absolute;
  top: 20%;
  margin-left: 25%;
  filter: brightness(0.5);
}

.button-desc {
  position: absolute;
  font-size: clamp(0.5rem, 3rem, 20rem);
  /* width: 18vw; */
  height: max-content;
  top: 50%;
  transform: translateY(-50%);
  width: 22vw;
  font-weight: 700;
}

.button-desc.left {
  left: -24vw;
  text-align: end;
}

.button-desc.right {
  left: 17vw;
  text-align: start;
}

@media only screen and (max-width: 769px) {
  .button-desc {
    font-size: clamp(0.5rem, 1rem, 1.5rem);
    width: 20vw;
  }

  .ACT-title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .button-desc {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1200px) {
  .button-desc {
    font-size: 32px;
  }
}

@media only screen and (max-width: 426px) {}

/* 
body {
  background-color: antiquewhite;
} */
#resource-holder {
  display: flex;
  flex-direction: column;
  min-height: 100rem;
}

#resource-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 2.5rem 0;
}

#training-subpage-title {
  font-weight: bold;
  font-size: 3rem;
  padding-top: 3rem;
}

#training-resource-top-img {
  width: 55rem;
}

#resource-upper-part-container {
  text-align: center;
}

#training-resource-info-expand {
  margin-top: 2px;
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;

  width: 100%;
  background-color: #e2c067;
  font-size: 1.7rem;
  font-weight: bold;
  height: 3rem;
  position: relative;
}

#training-resource-info-expand:hover {
  cursor: pointer;
}

#resource-list-text {
  text-align: center;
  /* padding-left: 47%; */
}

.resource-expansion-button {
  height: 5rem;
  padding: 1.5rem;
  position: absolute;
  right: 0;
}

.dropDownRect {
  z-index: 1;
  height: 3rem;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #f9e3ad;
  font-size: 1rem;
  border-top: 1px solid #e2c067;
  color: black;
}

#dropDownRect {
  cursor: pointer;
}

.dropDownRect.selected {
  background-color: #e2c067;
}

#training-resource-pdf-icon {
  display: flex;
  justify-content: flex-end;
}

#training-resource-pdf-image {
  padding-top: 0.5rem;
  width: 7rem;
}

#resource-content-sections {
  margin: 0.5rem auto;
  width: 55rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-justify: auto;
}

#resource-session-one {
  width: 100%;
  background-color: rgba(232, 200, 106, 0.5);
  margin-bottom: 0.5rem;
  padding: 1rem 1rem;
  border-radius: clamp(10px, 1rem, 100px);
}

#resource-session-one>img {
  max-width: 100%;
  max-height: 100%;
}

#session1-title {
  font-size: 32px;
  text-align: left;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.resource-session-caption {
  font-size: 1.5rem;
  padding-top: 1.5rem;
}

.resource-session-img {
  width: 100%;
}

.resource-session-paragraph {
  text-align: left;
  padding: 1rem 1rem;
  line-height: 1.6;
  font-size: 1.5rem;
}

#resource-session-two {
  width: 100%;
  background-color: rgba(232, 200, 106, 0.5);
  padding: 1rem;
  margin-bottom: 0.5rem;
  padding: 1rem 1rem;
  border-radius: clamp(10px, 1rem, 100px);
}

#resource-session-two>img {
  max-width: 100%;
  max-height: 100%;
}

#resource-session-three {
  width: 100%;
  background-color: rgba(232, 200, 106, 0.5);
  margin-bottom: 0.5rem;
  padding: 1rem 1rem;
  border-radius: clamp(10px, 1rem, 100px);
}

#resource-session-three>img {
  max-width: 100%;
  max-height: 100%;
}

#resource-session-four {
  width: 100%;
  background-color: rgba(232, 200, 106, 0.5);
  margin-bottom: 2.5rem;
  padding: 1rem 1rem;
  border-radius: clamp(10px, 1rem, 100px);
}

#resource-session-four>img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 900px) {
  .resource-expansion-button {
    height: 5rem;
  }

  #training-resource-info-expand {
    height: 2rem;
  }

  .resource-expansion-button {
    height: 4.5rem;
  }

  #resource-content-sections {
    width: 30rem;
  }

  #training-resource-top-img {
    width: 30rem;
  }
}

@media screen and (max-width: 700px) {
  .resource-expansion-button {
    height: 6rem;
  }
}

@media screen and (max-width: 769px) {
  #training-resource-pdf-image {
    padding-top: 0.5rem;
    /* width: 6rem; */
  }

  #training-subpage-title {
    font-size: 2.5rem;
  }

  .dropDownRect {
    height: 2rem;
  }

  /*
  #resource-list-text {
    text-align: center;
  }
  */
}

@media screen and (max-width: 432px) {
  .resource-expansion-button {
    height: 6rem;
  }

  #resource-content-sections {
    margin: 0.8rem auto;
  }

  #resource-content-sections {
    width: 22rem;
  }

  #training-resource-top-img {
    width: 22rem;
  }

  .resource-expansion-button {
    height: 4rem;
  }

  #training-subpage-title {
    font-size: 2rem;
    padding-top: 2rem;
  }

  #resource-list-text {
    font-size: 0.8rem;
  }

  /* #training-resource-pdf-image {
    width: 4rem;
  } */

  #resource-session-one {
    padding: 0.5rem 0.5rem;
    margin-bottom: 0.5rem;
  }

  #resource-session-two {
    padding: 0.5rem 0.5rem;
    margin-bottom: 0.5rem;
  }

  #resource-session-three {
    padding: 0.5rem 0.5rem;
    margin-bottom: 0.5rem;
  }

  #resource-session-four {
    padding: 0.5rem 0.5rem;
    margin-bottom: 0.5rem;
  }

  #training-resource-info-expand {
    height: 1.5rem;
  }

  #resource-content-sections {
    margin: 0.3rem auto;
  }

  /* //[back] is below  */
  .dropDownRect {
    height: 4.2rem;
    font-size: 1rem;
  }

  /* #resource-list-text {
    padding-left: 46.5%;
  } */

  #training-resource-info-expand {
    height: 1.7rem;
  }

  /* .resource-expansion-button {
    
  } */
}
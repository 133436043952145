/* useless */
.login-header {
  background-color: #fffaeb;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.login-container {
  display: flex;
  justify-content: center;
  background-color: #fffaeb;
  padding: 15%;
  padding-top: 50px;
  padding-bottom: 50px;
  flex: 3;
}

.login-alert {
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
}

.login-success-alert {
  /* width: 300px;
  height: 30px; */
  position: fixed;
  top: 10%;
  border-radius: 99rem;
  background: lightgreen;
  padding: 0.8126rem 3.676rem;
  color: white;
}

.login-failure-alert {
  width: 100%;
  padding-left: 5%;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 18px;
  color: red;
}

.login-failure-alert img {
  height: 20px;
  width: auto;
  margin-right: 5px;
}

.login-failure-alert p {
  height: 100%;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: red;
}

.login-box {
  width: 70vw;
  height: calc(70vw * 0.66287215);
  max-width: 1008px;
  max-height: 668px;
  display: flex;
  flex-direction: row;
  font-family: "Open Sans", "Noto Serif TC", sans-serif;
  background-image: url(Login-bg-desktop.webp);
  background-size: contain;
  background-repeat: no-repeat;
}

.login-box-left {
  width: fit-content;
  flex: 6;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 5%;
  padding-top: 17%;
}

.login-box-left-para {
  position: relative;
  left: 5%;
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
}

.login-box-left-input {
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 30px;
  margin-bottom: 20px;
  padding-left: 5%;
  padding-right: 45px;
  font-weight: 400;
  font-size: 17px;
  line-height: 170%;
}

.login-box-left>div:first-of-type>img {
  height: 28px;
  float: right;
  position: relative;
  top: -53px;
  left: -10px;
  margin-bottom: -28px;
  padding: 0;
}

.login-box-left-validation-alert-container {
  height: 20px;
  position: relative;
  top: -3%;
  display: flex;
}

.login-box-left-validation-alert {
  height: 5%;
  position: relative;
  top: -4%;
  margin-bottom: 0px;
  padding-left: 5%;
  text-align: left;
  font-size: 15px;
  color: red;
}

.login-box-left-forget-button-container {
  width: fit-content;
  position: relative;
  margin-left: auto;
}

.login-box-left-forget-button {
  position: relative;
  right: 5%;
  margin-bottom: 0px;
  color: #bc894e;
  font-size: 20px;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: right;
}

.login-box-left-login-button {
  border: none;
  box-sizing: border-box;
  /* height: 50px;
  margin-top: 10px;
  margin-left: 30%;
  margin-right: 30%; */
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #f5f3ed;
  background: #e2c067;
  border-radius: 4px;
  margin: auto;
  padding: 0.8126rem 3.676rem;
}

.button.arrow-button {
  border: none;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  color: #f5f3ed;
  background: #e2c067;
  border-radius: 4px;
}

.login-message-header-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}

.login-box-right {
  flex: 6;
  text-align: center;
  /* padding: 5%; */
  padding: 1rem;
  padding-top: 2.5%;
}

.login-box-right-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  margin: 0;
}

.login-box-right-para {
  font-size: 1rem;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
}

input:focus {
  outline: none;
}

@media only screen and (max-width: 500px) {
  .login-container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .login-box {
    height: calc(90vw * 1.804580152);
    width: 90vw;
    max-width: none;
    max-height: none;
    min-width: 309px;
    min-height: 585px;
    flex-direction: column-reverse;
    background-image: url(Login-bg-mobile.webp);
  }

  .login-failure-alert {
    font-size: 14px;
  }

  .login-box-left {
    flex: 3;
    padding-top: 5%;
    padding-left: 15%;
    padding-right: 15%;
    width: auto;
  }

  .login-box-left-para {
    font-size: 16px;
    line-height: 20px;
  }

  .login-box-left-input {
    height: 25px;
    margin-bottom: 10px;
    padding-right: 42px;
    font-size: 14px;
    line-height: 100%;
  }

  .login-box-left>div:first-of-type>img {
    height: 22px;
    top: -33px;
    margin-bottom: -22px;
  }

  .login-box-left-validation-alert {
    top: -3%;
    font-size: 12px;
  }

  .login-box-left-forget-button {
    font-size: 15px;
    line-height: 20px;
  }

  .login-box-left-login-button {
    /* height: 40px; */
    min-width: 96px;
    /* margin-top: 10px; */
    /* margin-left: 32%; */
    /* margin-right: 32%; */
    font-size: 19px;
    line-height: 24px;
  }

  .login-box-right {
    flex: 4;
    padding-bottom: 0%;
  }

  .login-box-right-title {
    margin-bottom: 0;
    font-size: 25px;
    font-weight: 700;
    line-height: 35px;
  }

  .login-box-right-para {
    font-size: clamp(11px, 3vw, 16px);
    font-weight: 450;
    line-height: 150%;
  }
}

@media only screen and (min-width: 501px) and (max-width: 769px) {
  .login-container {
    padding-left: 15%;
    padding-right: 15%;
  }

  .login-box {
    height: calc(70vw * 1.804580152);
    width: 70vw;
    max-width: none;
    max-height: none;
    min-width: 384px;
    min-height: 714px;
    flex-direction: column-reverse;
    background-image: url(Login-bg-mobile.webp);
  }

  .login-box-left {
    flex: 3;
    padding-top: 0%;
    padding-left: 15%;
    padding-right: 15%;
    width: auto;
  }

  .login-box-left-para {
    font-size: 19px;
    line-height: 25px;
  }

  .login-box-left-input {
    height: 30px;
    margin-bottom: 15px;
    padding-right: 42px;
    font-size: 16px;
    line-height: 100%;
  }

  .login-box-left>div:first-of-type>img {
    height: 24px;
    top: -41px;
    margin-bottom: -24px;
  }

  .login-box-left-validation-alert {
    font-size: 13px;
  }

  .login-box-left-forget-button {
    font-size: 17px;
    line-height: 25px;
  }

  .login-box-left-login-button {
    margin-top: 15px;
    /* margin-left: 35%;
    margin-right: 35%; */
    /* height: 35px; */
    min-width: 100px;
    font-size: 20px;
    line-height: 24px;
  }

  .login-box-right {
    flex: 4;
    padding: 10%;
    padding-bottom: 0px;
  }

  .login-box-right-title {
    /* margin-bottom: 20px; */
    font-size: 25px;
    font-weight: 700;
    line-height: 0px;
  }

  .login-box-right-para {
    font-size: 17px;
    font-weight: 450;
    line-height: 120%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  .login-container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .login-box {
    width: 90vw;
    height: calc(90vw * 0.66287215);
  }

  .login-box-left {
    padding-top: 13%;
  }

  .login-box-left-para {
    font-size: 19px;
    line-height: 30px;
  }

  .login-box-left-input {
    height: 35px;
    font-size: 16px;
    line-height: 140%;
  }

  .login-box-left>div:first-of-type>img {
    top: -51px;
  }

  .login-box-left-validation-alert {
    font-size: 12px;
  }

  .login-failure-alert {
    font-size: 15px;
  }

  .login-box-left-forget-button {
    font-size: 15px;
    line-height: 30px;
  }

  .login-box-left-login-button {
    max-height: 35px;
    min-height: 30px;
    margin-top: 10px;
    font-size: 18px;
  }

  .login-box-right {
    flex: 6;
    padding: 2%;
    padding-top: 5%;
  }

  .login-box-right-title {
    font-size: 35px;
    line-height: 35px;
  }

  .login-box-right-para {
    font-size: 15px;
    line-height: 25px;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .login-container {
    padding-left: 10%;
    padding-right: 10%;
  }

  .login-box {
    width: 80vw;
    height: calc(80vw * 0.66287215);
  }

  .login-box-left {
    padding-top: 15%;
  }

  .login-box-left-validation-alert {
    font-size: 12px;
  }

  .login-box-left-forget-button {
    line-height: 35px;
  }

  .login-box-left-login-button {
    min-height: 35px;
  }

  .login-box-right {
    flex: 6 1;
    padding-left: 2%;
    padding-right: 2%;
  }

  .login-box-right-para {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
  }
}

/* 
//old css
.login-page-container {
    background: linear-gradient(90deg, #0b8de8, #1fc8db);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .login-page-box {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 400px;
  }
  
  .header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="password"] {
    border: none;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 100%;
  }
  
  #error {
    color: #f00;
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  #login-button {
    background-color: #1fc8db;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px;
    transition: background-color 0.2s ease-in-out;
    width: 100%;
  }
  
  #login-button:hover {
    background-color: #0b8de8;
  }
   */
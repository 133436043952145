.footer {
  background: #c5d8e8;
  color: #002642;
  padding: 3rem 1rem;
}

.footer>div {
  margin-top: 1rem;
}

.footer>div:first-of-type {
  margin-top: 0;
}

.footer .site-map:not(:first-of-type) {
  margin-top: 1rem;
}

.footer .site-map a {
  color: #002642;
  font-size: 1rem;
  font-weight: 700;
  display: block;
  margin-top: 1rem;
}

.footer .site-map a:first-of-type {
  margin-top: 0;
  padding-left: 0;
  border-left: none;
}

.footer .institution-icons-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer .institution-icons-container img {
  max-height: 4rem;
}

.footer .copyright {
  font-weight: 700;
  margin-top: 1rem;
}

.footer .copyright span {
  color: #002642;
  display: block;
}

.footer .bottom-block {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.footer .bottom-block .left-block {
  order: 2;
}

.footer .bottom-block .right-block {
  order: 1;
  align-self: flex-start;
}

.footer .social-media {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer .social-media .title {
  font-weight: 700;
  font-size: 1rem;
  text-align: end;
}

.footer .social-media .icons img {
  aspect-ratio: 1;
  max-height: 2rem;
  margin-left: 1rem;
}

.footer .social-media .icons a:first-of-type img {
  margin-left: 0;
}

.footer .version {
  display: flex;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 600;
  color: #002642;
}

.text-pointer-style {
  cursor: pointer
}

/* Responsive area */
/* Small devices (landscape phones, 576px and up) */
@media only screen and (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .footer {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .footer .bottom-block {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .footer .site-map a {
    border-left: 2px solid #002642;
    padding: 0 1rem;
    display: inline;
    margin-top: 0;
    font-size: 1.375rem;
  }

  .footer .bottom-block .left-block {
    order: 1;
  }

  .footer .bottom-block .right-block {
    order: 2;
    align-self: flex-end;
    flex-shrink: 0;
  }

  .footer .social-media .title {
    font-size: 1.375rem;
  }

  .footer .copyright span {
    display: inline;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .footer {
    padding-left: 10vw;
    padding-right: 10vw;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {}
.publicity-page {
  display: flex;
  flex-direction: column;
}

body {
  background-color: #fffaeb;
}

.publicity-title {
  font-size: 46px;
  font-weight: bold;
}

.publicity-subtitle {
  font-size: 1.9rem;
  font-weight: bold;
}

.cardContentContainer {
  align-items: stretch;
  position: relative;
  /* animation: fadeInAnimation 1s normal forwards; */
}
.cardContentContainer p {
  /* position: absolute; */
  bottom: 16px;
}

.cardContentContainer div {
  font-size: 24px;
  /* margin-bottom: 10px;
  margin-top: 10px; */
}
.whats-cardImage-container {
  border-radius: 10px;
}

.cardImage-container {
  border-radius: 10px;
}

.cardTitle {
  flex-grow: 1;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
}

.cardTime {
  font-size: 1rem;
}
.pageContentContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 97%;
  /* max-width: 1400px; */
}

div.scrollTop {
  width: 100%;
  padding-bottom: 20px;
  padding-right: 0px;
}

div#promotion.pageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-image: url("./Group\ 264.webp"), url("./Group\ 258.webp");
  background-size: 25vw, 100%;
  background-repeat: no-repeat;
  background-position: 70vw calc(100px - 5vw), center 110px;
}

#promotion div.pageContentContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#promotion div.pageTitle {
  text-align: "center";
  margin: 2.5rem 0;
}

#promotion div.containerWrapper {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 85%;
  /* max-width: 1400px; */
}

#promotion div.cardContainer {
  margin: 0 auto;
  margin-bottom: 80px;
  width: 100%;
  text-align: left;
}
#promotion div.scrollContainer {
  display: flex;
  flex-flow: row;
  gap: 1rem;
  justify-content: left;
  position: relative;
  z-index: 0;
}

#promotion div.cardContentContainer {
  display: flex;
  flex-direction: column;
  width: 23.8%; /*Change to 33.33% for 3 cards per row */

  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #ede0a2;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0.7rem; /* Change to 10% for equal padding on left and right */

  /* Set the height to 80% of the width */
  padding-bottom: 0.7rem;
}

/* #publicity-scroll-to-top {
  width: 4vw;
  position: sticky !important;
  bottom: 5rem;
  margin-left: auto;
  margin-right: 3vw;
} */

.scroll-to-top img {
  width: clamp(60px, 100%, 80px);
  display: block;
  margin-left: auto;
  margin-right: 0;
}

@media only screen and (max-width: 769px) {
  #promotion div.cardContentContainer {
    width: 100%; /*Change to 33.33% for 3 cards per row */
  }
  #promotion .about-us-content-section {
    background-position: center 15%;
  }
  #promotion div.pageTitle {
    margin: 2.5rem 0;
  }
  /* #publicity-scroll-to-top {

  } */
  #publicity-scroll-to-top img {
    width: clamp(50px, 6vw, 100px);
  }
}

@media only screen and (max-width: 370px) {
  #publicity-scroll-to-top img {
    width: clamp(40px, 6vw, 100px);
  }

  #publicity-scroll-to-top {
    bottom: 1% !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  #promotion div.cardContentContainer {
    width: 50%; /*Change to 33.33% for 3 cards per row */
  }
  #promotion div.pageTitle {
    margin: 2.5rem 0;
  }

  #publicity-scroll-to-top img {
    width: clamp(50px, 6vw, 100px);
  }
}

@media only screen and (min-width: 1200px) {
  #promotion div.cardContentContainer {
    width: 32.5%; /*Change to 33.33% for 3 cards per row */
  }

  #publicity-scroll-to-top img {
    width: clamp(50px, 6vw, 100px);
  }
}
#publicity-scroll-to-top {
  position: sticky;
  bottom: 2vw;
  height: 4rem;
  /* margin: 2% auto 2% 93%; */
  /* right: 50px; */

  width: 100%;
  /* max-width: 1500px; */
  pointer-events: none;
}

#publicity-scroll-to-top img {
  width: 40px;
  float: right;
  cursor: pointer;
  pointer-events: all;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading {
  font-size: 5em;
  color: black;
  font-weight: 500;
}
.loadingPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loadingPage h3 {
  font-weight: bold;
  /* margin-right: 1em; */
  font-size: 2.4em;
}
.loadingPage img {
  max-width: 100%;
  height: auto;
}
.page404 {
  text-align: center;
}
.arrowBtnContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.cardImage-container {
  width: 100%;
  overflow: hidden;
}
.cardImage {
  width: 100%;
  object-fit: cover;
  /* height: 15rem; */
  overflow: hidden;
  aspect-ratio: 16/9;
}

.whats-cardImage-container {
  width: 100%;
  overflow: hidden;
}
.whats-cardImage {
  width: 100%;
  object-fit: cover;
  /* height: 15rem; */
  overflow: hidden;
  aspect-ratio: 1/1;
}

.imgControlBtn {
  /* margin-right: 10px; */
  background-color: transparent;
  border: none;
  border-radius: 50%;
}

.cardContentContainer img:hover {
  cursor: pointer;
}
.cardContentContainer:hover {
  box-shadow: 0 8px 1rem 0 rgba(0, 0, 0, 0.24), 0 4px 8px 0 rgba(0, 0, 0, 0.16) !important;
}
.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-number-container {
  text-align: center;
}
.publicty-card-page-number-container {
  font-size: 1.5rem;
  position: relative;
}

.publicty-card-page-number-container img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.publicty-card-page-number {
  position: relative;
  z-index: 5;
}

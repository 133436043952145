.research-wrapper {
  display: flex;
  flex-direction: column;
  /* min-height: 100rem; */
  overflow-x: clip;
}

#research.content-section {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 2rem;
  width: 100%;
  flex: 1;
}

#research .section-bar {
  width: 35rem;
  align-self: flex-start;
  padding-top: 2rem;
  padding-bottom: 5rem;
  top: calc(100px + 5rem);
}
#research .section-bar-container {
  background-color: unset;
  padding-left: 2rem;
  width: 70%;
}
#research .section-bar-row a {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#research .section-bar-img {
  height: clamp(20px, 2rem, 30px);
  width: clamp(20px, 2rem, 30px);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  background: url(./article.webp) no-repeat center / contain;
}
#research .current .section-bar-img {
  background: url(./Group_168.webp) no-repeat center / contain;
}
/* #research .section-bar-img img {
  width: 100%;
} */
#research .section:hover {
  background: unset;
}
#research .section-bar-spacer {
  margin-left: clamp(7.5px, 0.75rem, 11.25px);
}

/* a {
  text-decoration: none !important;
  font-weight: 500;
  font-size: 1.4rem;
} */

#research .module-container {
  margin: 0 0 0 0;
  width: 77%;
  align-items: flex-start;
}
.research-session {
  width: 85%;
  margin: 20px 0 0 0;
  padding-right: 3rem;
}
.research-session-title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.research-session-content {
  background: rgba(232, 200, 106, 0.5);
}
.research-item-session-content {
  padding: 1.5rem 2rem 0rem 2rem;
  position: relative;
}
.downloadBtn {
  display: flex;
  justify-content: flex-end;
  width: clamp(100px, 9rem, 150px);
}

.download-container {
  margin-bottom: 2rem;
  display: flex;
  justify-content: flex-end;
}

.research-item-session-row {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: flex-start;
}

.research-item-session-title {
  flex: 1 0 9rem;
  margin-right: 5px;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-weight: 500;
  align-self: flex-start;
  text-align: left;
}

.research-item-colon {
  flex: 0 0 2rem;
  text-align: left;
  margin-right: auto;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-weight: 500;
  align-self: flex-start;
}

.research-item-session-text {
  flex: 4 0 9rem;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  align-self: flex-start;
  /* text-align: justify; */
}
.research-detail-session-content {
  padding: 1rem;
}
.research-theoretical-session-content,
.research-publication-session-content {
  padding: 2.3rem;
}

.research-detail-session-row {
  width: 100%;
}
.research-detail-session-title {
  width: 100%;
  padding: 0 2.3rem 1rem 0;
  margin-bottom: 0;
  font-size: 1.3rem;
  font-weight: 500;
}
.research-detail-session-text {
  white-space: break-spaces;
  background: rgb(245, 245, 219);
  padding: 1rem 2rem 0 1rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  border-radius: clamp(2px, 0.6rem, 5px);
  line-height: 1.6;
}

.research-detail-session-text p {
  margin: 0;
}

#research .section {
  text-align: left;
  font-size: clamp(0.5rem, 1.4rem, 3rem);
  font-weight: 500;
  width: 100%;
  padding: 0.5rem 1rem;
  justify-content: start;
}
.researchSub-scroll-to-top {
  /* position: sticky;
  bottom: 2rem;
  right: 4rem;
  margin: 2% 0 2% auto;
  cursor: pointer;
  width: clamp(8%, 4rem, 20%); */
  position: sticky;
  bottom: 2vw;
  height: 4rem;
  width: 100%;
  /* max-width: 1500px; */
}
.researchSub-scroll-to-top img {
  /* width: clamp(60px, 100%, 80px); */
  /* position: absolute; */
  width: 40px;
  float: right;
  cursor: pointer;
  margin-right: 1rem;
}

.displayNone {
  display: none !important;
}

.displayNoneTitle {
  margin-bottom: 0px !important;
}

.research-detail-session-title-zh_hant::after {
  content: "：";
}
.research-detail-session-title-en::after {
  content: ":";
}
@media screen and (max-width: 1025px) {
  #research .section-bar-container {
    padding-left: 0;
  }
  #research .section-bar {
    width: 25rem;
    align-self: flex-start;
    padding-top: 2rem;
    padding-bottom: 5rem;
  }

  #research .module-container {
    margin: 0;
    width: 100%;
  }

  .research-session {
    width: 90%;
    margin: 20px 0 0 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 769px) {
  /* #research.content-section {
    column-gap: 0;
  } */

  #research .section-bar {
    width: 16rem;
  }
  #research .section {
    font-size: clamp(0.5rem, 1.1rem, 3rem);
  }
}
@media screen and (max-width: 768px) {
  .research-item-session-content {
    padding: 1rem 2rem 1rem;
  }
  #research .module-container {
    margin: 0;
    width: 100%;
  }
  #research .research-item-session {
    align-items: justify;
  }
  #research .section-bar-container {
    padding-left: 2rem;
  }
  #research .section-bar {
    position: sticky;
    width: 13rem;
  }
  .researchSub-scroll-to-top {
    /* width: clamp(5%, 10vw, 10%); */
    margin: 2% 0 2% auto;
    right: 7rem;
  }

  .download-container {
    justify-content: center;
  }

  .downloadBtn {
    justify-content: center;
    width: clamp(100px, 9rem, 150px);
  }
  .research-item-session-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .research-item-session-title {
    flex: 0 0 100%;
    /* flex-grow: 1; */
    text-align: left;
    font-size: 1.3rem;
    font-weight: 600;
    align-self: flex-start;
  }
  .research-item-colon {
    display: none;
    /* flex: 0 0 0; */
  }
  .research-item-session-text {
    flex: 0 0 100%;
    margin-bottom: 2rem;
    font-size: 1.1rem;
    /* text-align: justify; */
  }
  .session-title#researchItemTitle {
    margin-bottom: 1.5rem;
  }
  .research-detail-session-title-zh_hant::after {
    content: "";
  }
  .research-detail-session-title-en::after {
    content: "";
  }

  .research-detail-session-title {
    padding: 0 0 1rem 0;
    text-align: center;
    margin-bottom: 0rem;
    font-size: 1.3rem;
    font-weight: 600;
    align-self: flex-start;
    margin-top: 1rem;
  }
  .research-detail-session-text {
    padding: 1rem 1rem 1rem;
    white-space: normal;
  }

  .section-bar-spacer {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 426px) {
  .session-title {
    margin-top: 0rem;
  }
  #research.content-section {
    column-gap: 0.3rem;
  }

  .research-session {
    padding-right: 0rem;
  }
  .research-detail-session-content {
    padding: 1rem;
  }
  .research-item-session-content {
    padding: 1rem 1rem 1rem;
    position: relative;
  }
  #research .module-container {
    margin: 0;
    width: 100%;
  }
  #research .research-item-session {
    align-items: justify;
  }
  #research .section-bar-container {
    padding-left: 1rem;
  }
  #research .section-bar {
    position: sticky;
    width: 7rem;
  }
  .researchSub-scroll-to-top {
    /* width: clamp(5%, 10vw, 10%); */
    margin: 2% 0 2% auto;
    right: 7rem;
  }

  .download-container {
    justify-content: center;
  }

  .downloadBtn {
    justify-content: center;
    width: clamp(100px, 9rem, 150px);
  }
  .research-item-session-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .research-item-session-title {
    flex: 0 0 100%;
    /* flex-grow: 1; */
    text-align: left;
    font-size: 1.3rem;
    font-weight: 600;
    align-self: flex-start;
  }
  .research-item-colon {
    display: none;
    /* flex: 0 0 0; */
  }
  .research-item-session-text {
    flex: 0 0 100%;
    margin-bottom: 2rem;
    font-size: 1.1rem;
    /* text-align: justify; */
  }
  .session-title#researchItemTitle {
    margin-bottom: 2vh;
  }
  .research-detail-session-title-zh_hant::after {
    content: "";
  }
  .research-detail-session-title-en::after {
    content: "";
  }
  .research-detail-session-title {
    text-align: center;
    margin-bottom: 0rem;
    font-size: 1.3rem;
    font-weight: 600;
    align-self: flex-start;
    margin-top: 1rem;
  }
  .research-detail-session-text {
    padding: 1rem 1rem 1rem;
    white-space: normal;
  }

  .section-bar-spacer {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .researchSub-scroll-to-top img {
    height: 40px;
    width: 40px;
  }
}
/* @media screen and (max-width: 300px) {
  #research .section-bar-container {
    padding-left: 3vw;
  }
  #research .section-bar-img {
    width: 10px;
  }
  #research .section-bar-spacer {
    margin-left: clamp(2px, 0.75vw, 11.25px);
  }
  #research .section {
    font-size: clamp(0.1rem, 1.7vw, 3rem);
  }
}

@media screen and (min-width: 1920px) {
  .research-item-session-content {
    padding: 3vw 4vw 1.5vw;
  }

  .research-item-session-row {
    flex-direction: row-reverse;
  }

  .research-item-session-title {
    text-align: left;
    width: 20%;
    margin-left: auto;
  }

  .research-item-session-text {
    width: 70%;
    margin-bottom: 1.5vw;
    font-size: 1.2rem;
  }

  .research-detail-session-text {
    padding: 2vw 1.5vw;
    font-size: 1.2rem;
    margin-bottom: 2vw;
  }
} */

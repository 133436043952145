body.dialog-open {
    overflow: hidden;
}

/* useless */
.forget-header {
    background-color: #fffaeb;

}

.forget-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.forget-container {
    display: flex;
    justify-content: center;
    background-color: #fffaeb;
    padding: 9%;
    padding-top: 50px;
    padding-bottom: 50px;
    font-family: "Open Sans", "Noto Serif TC", sans-serif;
    font-style: normal;
    flex: 3;
}

.forget-bg {
    width: 82vw;
    height: calc(82vw*(599/1142));
    max-width: 1142px;
    max-height: 599px;
    font-family: "Open Sans", "Noto Serif TC", sans-serif;
    background-image: url(Forget\ Password-bg.webp);
    background-size: contain;
    background-repeat: no-repeat;
}

.forget-box {
    position: relative;
    left: 45%;
    width: 55%;
    padding: 6%;
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.forget-box-title {
    padding-left: 5%;
    padding-bottom: 1%;
    font-weight: 700;
    font-size: 38px;
    line-height: 131%;
}

.forget-box-para {
    padding-left: 5%;
    padding-bottom: 1%;
    font-weight: 500;
    font-size: 22px;
    line-height: 160%;
}

.forget-box-input-text {
    margin-bottom: 0px;
    padding-left: 5%;
    font-weight: 500;
    font-size: 22px;
}

.forget-box-input {
    height: 50px;
    padding-bottom: 1%;
    border: none;
    border-radius: 30px;
    margin-bottom: 5px;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 20px;
}

.forget-box-validation-alert {
    position: relative;
    top: -3%;
    margin-bottom: 0px;
    padding-left: 5%;
    text-align: left;
    font-size: 15px;
    color: red;
}

.forget-box-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.forget-box-button {
    margin-top: 30px;
    border: none;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;
    letter-spacing: -0.02em;
    text-align: center;
    border-radius: 4px;
}

.forget-box-continue-button {
    width: 180px;
    height: 45px;
    color: #f5f3ed;
    background: #e2c067;
}

.forget-box-back-button {
    width: 150px;
    height: 40px;
    background: #f4e1ab;
}

.forget-dialog {
    border: none;
    border-radius: 10px;
    padding: 0px;
    background: #f4e1ab;
}

dialog::backdrop {
    background-color: rgba(0, 0, 0, 0.7);
}

.forget-dialog-bg {
    width: 82vw;
    height: calc(82vw*(599/1142));
    max-width: 1142px;
    max-height: 599px;
    font-family: "Open Sans", "Noto Serif TC", sans-serif;
    background-image: url(Forget\ Password\ Pop\ Up-bg.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #f4e1ab;
}

.forget-dialog-box {
    position: relative;
    top: 45%;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.forget-dialog-para {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.2;
}

.forget-dialog-button {
    width: 160px;
    height: 50px;
    margin-top: 4%;
    border: none;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #f5f3ed;
    background: #e2c067;
    border-radius: 4px;
}

@media only screen and (max-width: 500px) {
    .forget-container {
        padding: 7%;
    }

    .forget-bg {
        width: 90vw;
        height: calc(90vw*(1182/653));
        max-width: none;
        max-height: none;
        min-width: 342px;
        min-height: 654px;
        font-family: "Open Sans", "Noto Serif TC", sans-serif;
        background-image: url(Forget\ Password-bg\ mobile.webp);
    }

    .forget-box {
        top: 45%;
        left: 0%;
        width: 100%;
        padding: 10%;
    }

    .forget-box-title {
        font-size: 20px;
    }

    .forget-box-para {
        padding-bottom: 0;
        margin-bottom: 10px;
        font-size: 16px;
    }

    .forget-box-input-text {
        font-size: 16px;
    }

    .forget-box-input {
        height: 30px;
        font-size: 15px;
    }

    .forget-box-validation-alert {
        font-size: 13px;
    }

    .forget-box-continue-button {
        width: 140px;
        height: 35px;
        margin-top: 5%;
        font-size: 20px;
    }

    .forget-box-back-button {
        width: 150px;
        height: 30px;
        margin-top: 5%;
        font-size: 20px;
    }

    .forget-dialog-bg {
        width: 85vw;
        height: calc(85vw*(805/653));
        min-width: 255px;
        min-height: 315px;
        font-family: "Open Sans", "Noto Serif TC", sans-serif;
        background-image: url(Forget\ Password\ popup-bg\ \ mobile.webp);
    }

    .forget-dialog-box {
        top: 46%;
        /* padding-top: 10%;
        padding-left: 5%;
        padding-right: 5%; */
    }

    .forget-dialog-para {
        margin-bottom: 10px;
        font-size: 15px;
        line-height: 150%;
    }

    .forget-dialog-button {
        width: 100px;
        height: 35px;
        margin-bottom: 1rem;
        margin-top: 0;
        font-size: 17px;
    }
}

@media only screen and (min-width: 501px) and (max-width: 768px) {

    .forget-container {
        padding: 10%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .forget-bg {
        width: 80vw;
        height: calc(80vw*(1182/653));
        max-width: 463px;
        max-height: 868px;
        font-family: "Open Sans", "Noto Serif TC", sans-serif;
        background-image: url(Forget\ Password-bg\ mobile.webp);
    }

    .forget-box {
        top: 45%;
        left: 0%;
        width: 100%;
    }

    .forget-box-title {
        font-size: 32px;
    }

    .forget-box-para {
        margin-bottom: 10px;
        font-size: 17px;
    }

    .forget-box-input-text {
        font-size: 17px;
    }

    .forget-box-input {
        height: 40px;
        font-size: 16px;
    }

    .forget-box-continue-button {
        margin-top: 25px;
    }

    .forget-box-back-button {
        margin-top: 25px;
    }

    .forget-dialog-bg {
        width: 75vw;
        height: calc(75vw*(805/653));
        max-height: none;
        min-width: 457px;
        min-height: 563px;
        font-family: "Open Sans", "Noto Serif TC", sans-serif;
        background-image: url(Forget\ Password\ popup-bg\ \ mobile.webp);
    }

    .forget-dialog-box {
        /* top: 50%; */
        /* padding-top: 8%; */
    }

    .forget-dialog-para {
        font-size: 18px;
        padding-top: 5%;
    }

    .forget-dialog-button {
        margin-top: 8%;
    }
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
    .forget-container {
        padding: 5%;
    }

    .forget-bg {
        width: 90vw;
        height: calc(90vw*(599/1142));
    }

    .forget-box {
        padding: 4%;
        padding-top: 10%;
    }

    .forget-box-title {
        padding-bottom: 0%;
        font-size: 28px;
    }

    .forget-box-para {
        padding-bottom: 0%;
        font-size: 16px;
    }

    .forget-box-input-text {
        font-size: 16px;
    }

    .forget-box-input {
        height: 40px;
        margin-bottom: 0px;
    }

    .forget-box-continue-button {
        margin-top: 15px;
        height: 37px;
        width: 148px;
        font-size: 20px;
    }

    .forget-box-back-button {
        margin-top: 15px;
        height: 37px;
        width: 145px;
        font-size: 20px;
    }

    .forget-dialog-bg {
        width: 92vw;
        height: calc(92vw*(599/1142));
    }

    .forget-dialog-para {
        font-size: 20px;
        line-height: 70%;
    }

    .forget-dialog-button {
        width: 140px;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {

    .forget-container {
        padding: 5%;
    }

    .forget-bg {
        width: 90vw;
        height: calc(90vw*(599/1142));
    }

    .forget-box-title {
        padding-bottom: 0%;
        font-size: 33px;
    }

    .forget-box-para {
        padding-bottom: 0%;
        font-size: 18px;
    }

    .forget-box-input-text {
        font-size: 18px;
    }

    .forget-box-input {
        height: 45px;
        font-size: 17px;
    }

    .forget-dialog-bg {
        width: 90vw;
        height: calc(90vw*(599/1142));
    }

    .forget-dialog-para {
        font-size: 23px;
        /* line-height: 70%; */
    }

}
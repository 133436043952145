/* header css */
/* Moved to Header2.css */
/*
.general-header {
  background-color: #faf4e1b3;
  position: sticky;
  top: 0;
  z-index: 999;
  backdrop-filter: blur(6px);
}
*/

.generalPageTitleContainer {
  text-align: center;
  margin-bottom: 50px;
  
}

.generalPageContainer {
  justify-content: center;
  align-items: center;
  width: 82.45%;
  margin: 50px auto;
  flex: 1;
}

/* TODO: remove this part after finish testing */
/*
.generalPageContainerNew {
  display: flex;
  flex-direction: column;
  /* TODO: min-height = 100vh - height of header+footer */
  /* use calc *//*
  min-height: 100vh;
  justify-content: flex-start;
  align-items: center;
  width: 82.45%;
  margin: 50px auto;
  flex: 1;
}
*/

.generalPageTitleInsidegeneralPageTitleContainer {
  font-size: 46px;
}

.what-is-section-bar {
  position: sticky;
  float: left;
  top: calc(6rem + 5rem);
  width: 20%;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 0%;
}

.what-is-section-bar-container {
  /* width: 150px; */
  width: 14rem;
  border-radius: 5px;
  background-color: #f5e6d7;
}

.what-is-section {
  /* width: 150px; */
  width: 100%;
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Open Sans","Noto Serif TC",sans-serif;
  margin: auto;
  justify-content: center;
}

.what-is-section:first-child {
  border-radius: 5px 5px 0 0;
}

.what-is-section:last-child {
  border-radius: 0 0 5px 5px;
}

.what-is-section-current {
  background-color: #f5df8f;
  font-size: 1rem;
  font-weight: 500;
}

.what-is-section:hover {
  background-color: #f5df8f;
  cursor: pointer;
}

.what-is-section-bar.scroll-up {
  position: fixed;
  top: calc(6rem + 5rem);
  margin-top: 0;
  z-index: 1000;
}

@media only screen and (max-width: 768px) {
  .what-is-section-bar {
    width: 100%;
    margin: 5px 0 0 0;
    align-items: center;
    position: relative;
    top: 1rem;
  }

  .what-is-section-bar-container {
    width: 90%;
  }

  .what-is-section {
    width: 100%;
    padding: 0.5rem 1.5rem;
    /* height: 40px; */
    display: flex;
    /* justify-content: flex-start; */
    align-items: center;
    margin: 0;
    border-bottom: 1px solid #fffaeb;
  }

  .what-is-section:last-child {
    border-bottom: none;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .what-is-section-bar {
    width: 20%;
    left: 10px;
    padding: 1rem;
  }

  .what-is-section-bar-container {
    /* width: fit-content; */
    width: 10rem;
  }

  .what-is-section {
    /* width: 130px; */
  }
}

@media only screen and (min-width: 1200px) {
  .what-is-section:first-child {
    border-radius: 5px 5px 0 0;
  }

  .what-is-section:last-child {
    border-radius: 0 0 5px 5px;
  }
}
.get-involved-wrapper {
  display: flex;
  flex-direction: column;
  overflow-x: clip;
}

.get-involved-content {
  background-image: url("./get-involved.webp");
  background-size: 100%;
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  aspect-ratio: 1 / 1;
  font-size: 38px;
  font-weight: bold;
  text-align: center;
  overflow-wrap: break-word;
}

.grid-item.green {
  border-top: 2rem solid;
  border-color: transparent;
  /* cursor: pointer; */
}

.grid-item.yellow {
  border-left: 2rem solid;
  border-color: transparent;
  cursor: pointer;
}

.grid-item.orange {
  border-right: 2rem solid;
  border-color: transparent;
  cursor: pointer;
}

.grid-item.teal {
  border-bottom: 2rem solid;
  border-color: transparent;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  margin-bottom: 60px;
}

.get-involved-title {
  font-size: 46px;
  font-weight: bold;
  margin-top: 2.5rem;
  margin-bottom: 40px;
  text-align: center;
}

@media only screen and (max-width: 425px) {
  .get-involved-title {
    font-size: 36px;
  }

  .grid-item {
    font-size: 12px;
  }

  .grid-item.green {
    border-top: 1rem solid;
    border-color: transparent;
  }

  .grid-item.yellow {
    border-left: 1rem solid;
    border-color: transparent;
  }

  .grid-item.orange {
    border-right: 1rem solid;
    border-color: transparent;
  }

  .grid-item.teal {
    border-bottom: 1rem solid;
    border-color: transparent;
  }
}


@media only screen and (min-width: 760px) and (max-width: 1100px) {
  .get-involved-title {
    font-size: 36px;
  }

  .grid-item.green {
    border-top: 1.5rem solid;
    border-color: transparent;
  }

  .grid-item.yellow {
    border-left: 1.5rem solid;
    border-color: transparent;
  }

  .grid-item.orange {
    border-right: 1.5rem solid;
    border-color: transparent;
  }

  .grid-item.teal {
    border-bottom: 1.5rem solid;
    border-color: transparent;
  }
}




@media only screen and (min-width: 426px) and (max-width: 920px) {
  .get-involved-title {
    font-size: 36px;
  }

  .grid-item {
    font-size: 1.5rem;
  }

  .grid-item.green {
    border-top: 1.5rem solid;
    border-color: transparent;
  }

  .grid-item-red-inner-text {
    border-left: 0.5rem solid;
    border-color: transparent;
    color: yellowgreen;
  }

  .grid-item.orange {
    border-right: 1.5rem solid;
    border-color: transparent;
  }

  .grid-item.teal {
    border-bottom: 1.5rem solid;
    border-color: transparent;
  }
}
/* .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  } */

/* body{
    background-color: antiquewhite;
  } */
.get-involved-wrapper {
  background-color: #fffaeb;
  display: flex;
  flex-direction: column;
  flex: 1;
  /* [back] resume all: min-height: 100vh;
  position: relative; */
}



.Int-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  /* margin: 2.3rem 0 2rem 0; */
  flex: 3;
  margin-top: 2.3rem;
  /* padding-bottom: 20rem;  [back] resume */

}

.footer-div {
  /* [back] resume all: position: absolute;
  bottom: 0;
  width: 100%;
  height: 20rem; */

}


.Int-content {
  margin: 0 auto;
  width: 62.6vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-justify: auto;

}

/* .Int-desc{
    padding: 3vw 5vw;
  } */

.Int-title {
  font-size: 46px;
  font-weight: bold;
  margin-bottom: 3rem;
}

.Int-contents-container {
  padding: 3.5vw 2.3vw;
}

.Int-callout-title {
  font-size: 28px;
  font-weight: 600;
  text-align: left;
  margin-left: 0;
  /* color: #000; */
}

.Int-callout-title {
  margin-bottom: 0.5vw;
}

.Int-callout-description,
.Int-callout-description a {
  text-align: left;
  padding: 1rem 1.5rem;
  line-height: 1.6;
  font-size: 1rem;
  font-weight: 400;
}

.Int-callout-description a {
  text-decoration: underline;
  padding: 0 !important;
}

.Int-callout-description a>span {
  text-decoration: underline;
  padding: 0 !important;
  color: #306188 !important;
}

.Int-callout-description {
  background-color: #f5f5dc;
}

.Int-callout-container {
  margin-bottom: 1.9vw;
}

/* .Int-callout-container:first-child  .Int-callout-title{
  } */
.Int-callout-description p {
  margin: 0;
}

.Int-contents-container {
  width: 100%;
  background-color: rgba(232, 200, 106, 0.5);
  padding: 1rem 2rem 0;
  margin-bottom: 2rem;
}

/* ipad size */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .Int-container {
    /* padding-bottom: 24rem; [back] resume */
  }

  .footer-div {
    /* height: 24rem; [back] resume  */
  }
}

/* body {
    background-color: antiquewhite;
  } */
@media (max-width: 768px) {
  .Int-content {
    width: 90%;
    margin: 30px auto;
  }

  .Int-callout-title {
    font-size: 1.4rem;
    margin-left: 15px;
  }

  .Int-callout-description,
  .Int-callout-description a {
    padding: 10px;
    font-size: 1rem;
  }

  .Int-title {
    font-size: 1.9rem;
    margin-bottom: 3rem;
  }

  .Int-contents-container {
    padding: 20px;
    margin-bottom: 20px;
  }





}